import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import Home from '../Pages/Home/Home';
import Cadastro from '../Pages/Cadastro/Cadastro';
import Login from '../Pages/Login/Login';
import Acesso from '../Pages/Acesso/Acesso';
import Perfil from '../Pages/Perfil/Perfil';
import Empresas from '../Pages/Empresas/Empresas';
import ProtectedRoute from './ProtectedRoute';

function Rotas() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/login" element={<ProtectedRoute element={<Login />} />} />
				<Route path="/cadastro" element={<ProtectedRoute element={<Cadastro />} />} />
				<Route path="/acesso" element={<ProtectedRoute element={<Acesso />} />} />
				<Route path="/perfil" element={<ProtectedRoute element={<Perfil />} />} />
				<Route path="/empresas" element={<ProtectedRoute element={<Empresas />} />} />
			</Routes>
		</Router>
	);
}

export default Rotas;
