import React from 'react';
import './SmallButton.css';

function SmallButton({ children, onClick, className }) {
  return (
    <button onClick={onClick} className={'Small-button ${className}'}>
      {children}
    </button>
  );
}

export default SmallButton; 
