import React from 'react';
import './Vbutton.css';

const VButton = ({ children, onClick, className, style, disabled }) => {
  return (
    <button 
      className={`v-button ${className}`} 
      onClick={onClick} 
      style={style} 
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default VButton;


