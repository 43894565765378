import React, { useState, useEffect, useRef } from 'react';
import './Perfil.css'; // Importar o CSS
import VButton from '../../Componentes/VButton/VButton';
import { useNavigate } from 'react-router-dom';
import { getCompanies, endVotation } from '../../services/Api';
import imgLogo from '../../assets/destaque-black.png';
import { localStorageHelper } from '../../utils/LocalStorageHelper';
import BigButton from '../../Componentes/BigButton/BigButton';
import Swal from 'sweetalert2';

function Perfil() {
	const [user, setUser] = useState({
		name: '-',
	});
	const [searchTerm, setSearchTerm] = useState('');
	const [categorias, setCategorias] = useState([]);
	const [empresasFiltradas, setEmpresasFiltradas] = useState(categorias);
	const listaRef = useRef(null);
	const [hasVoted, sethasVoted] = useState(false);

	const navigate = useNavigate();

	// Função para capturar o valor do campo de pesquisa
	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	// useEffect que filtra as empresas com base no termo de pesquisa
	useEffect(() => {
		const validateCompanies = async (categorias) => {
			let searchItems = [];
			for await (const categoria of categorias) {
				const empresas = categoria.companies;

				let isCategory = categoria.name.toLowerCase().includes(searchTerm.toLowerCase());
				if (!isCategory) {
					for await (const empresa of empresas) {
						let isCompany = empresa.name.toLowerCase().includes(searchTerm.toLowerCase());
						if (isCompany) {
							searchItems.push(categoria);
							break;
						}
					}
				}
				if (isCategory) {
					searchItems.push(categoria);
				}
			}
			setEmpresasFiltradas(searchItems);
		};
		validateCompanies(categorias);
	}, [searchTerm, categorias]);

	const handleVote = (categoria) => {
		navigate(`/empresas`, { state: { categoria: categoria } });
	};

	useEffect(() => {
		setUser(JSON.parse(localStorageHelper.getItem('user')));

		getCompanies()
			.then((response) => {
				for (const categoria of response.data) {
					if (categoria.hasVoted == 1) {
						sethasVoted(true);
						break;
					}
				}
				setCategorias(response.data);
			})
			.catch((err) => {
				console.log(err);
			});

		const inputElement = document.querySelector('.search-input');

		if (inputElement) {
			inputElement.addEventListener('input', handleSearch);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('input', handleSearch);
			}
		};
	}, []);

	const handleScroll = (event) => {
		if (listaRef.current) {
			listaRef.current.scrollBy({
				top: event.deltaY,
				behavior: 'smooth',
			});
		}
	};

	const handleEncerrar = () => {
		Swal.fire({
			title: 'Voce tem certeza?',
			text: 'Ao encerrar a votação, não será possível acessar a plataforma e votar novamente em nenhuma empresa.',
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sim, encerrar!',
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await endVotation();
					Swal.fire({
						title: 'Encerrado!',
						text: 'Sua participação foi encerrada com sucesso.',
						icon: 'success',
					}).then(() => {
						navigate('/');
					});
				} catch (err) {
					console.log(err);
				}
			}
		});
	};

	return (
		<div class="container text-center col-lg-8 col-md-10 col-sm-10">
			<div className="row justify-content-between px-2 mt-4">
				<div class="card card-principal col-lg-12 col-md-12 col-sm-12 shadow-lg">
					<div class="card-body col-12 justify-content-center">
						<div className="row justify-content-start mt-0 mb-0"></div>
						<div className="row justify-content-center">
							<img src={imgLogo} alt="logo-destaque" className="img-logo-destaque" />
						</div>
						<h3>Olá, {user.name.split(' ')[0]}.</h3>
						{hasVoted ? (
							<>
								<div className="col-12 mt-2 mb-2">
									<BigButton onClick={handleEncerrar} className="login-button">
										<b>
											ENCERRAR VOTAÇÃO &nbsp;
											<i className="fas fa-solid fa-check"></i>
										</b>
									</BigButton>
								</div>
							</>
						) : (
							<p>Fique atento, só é possível votar uma vez por categoria.</p>
						)}
						<hr />
						<div class="search-container mb-3">
							<input
								type="text"
								class="search-input"
								placeholder="Pesquisar categorias ou empresas"
								onChange={handleSearch}
							/>
							<i class="fas fa-search search-icon"></i>
						</div>
						<div class="container text-center pb-4 container-cards">
							<div className="row align-items-stretch" ref={listaRef} onWheel={handleScroll}>
								{empresasFiltradas.length === 0 ? (
									<div
										className="d-flex justify-content-center align-items-center"
										style={{ height: '200px', width: '100%' }}
									>
										<h1>. . .</h1>
									</div>
								) : (
									empresasFiltradas.map((categorias, index) => (
										<div key={index} className="col-6 d-flex">
											<div className="card card-categoria mb-4 flex-fill">
												<div className="card-body d-flex flex-column align-items-center">
													<h6 className="mt-2" translate="no">
														{categorias.name}
													</h6>
													<div className="mt-2 col-12">
														{categorias.hasVoted === 1 ? (
															<VButton
																className="voted"
																disabled
																style={{
																	backgroundColor: 'white',
																	color: 'black',
																	border: '1px solid black',
																}}
															>
																Você já votou
															</VButton>
														) : (
															<VButton onClick={() => handleVote(categorias)}>Votar</VButton>
														)}
													</div>
												</div>
												<div className="card-footer linear-bg-color text-white p-1">
													{categorias.companies.length} empresa(s)
												</div>
											</div>
										</div>
									))
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Perfil;
