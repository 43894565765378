import axios from 'axios';
import { localStorageHelper } from '../utils/LocalStorageHelper';

const api = axios.create({
	// baseURL: 'http://localhost:3000/api',
	baseURL: 'https://ace-campos-api.azurewebsites.net/api',
	headers: {
		'Content-Type': 'application/json',
	},
});

const showLoading = () => {
	const loader = document.getElementById('loader');
	if (loader) {
		loader.style.display = 'block';
	}
};

const hideLoading = () => {
	const loader = document.getElementById('loader');
	if (loader) {
		loader.style.display = 'none';
	}
};

api.interceptors.request.use(
	(config) => {
		showLoading();
		return config;
	},
	(error) => {
		hideLoading();
		return Promise.reject(error);
	},
);

api.interceptors.response.use(
	(response) => {
		hideLoading();
		return response;
	},
	(error) => {
		hideLoading();
		return Promise.reject(error);
	},
);

export const login = async (document) => {
	const cpf = document.replace(/\D/g, '');
	const response = await api.post('/users/login', { document: cpf });
	return response;
};

export const registration = async (data) => {
	const cpf = data.document.replace(/\D/g, '');
	const cellphone = data.cellphone.replace(/\D/g, '');
	const response = await api.post('/users/registration', {
		name: data.name,
		mail: data.mail,
		document: cpf,
		cellphone: cellphone,
	});
	return response;
};

export const otpValidation = async (data) => {
	const code = data.code.replace(/\D/g, '');
	const cpf = data.document.replace(/\D/g, '');

	return await api.post('/codes', {
		document: cpf,
		code: code,
	});
};

export const endVotation = async () => {
	const token = localStorageHelper.getItem('token');
	console.log(token);

	const response = await api.post(
		'/users/done',
		{},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return response;
};

export const aboutMe = async () => {
	const token = localStorageHelper.getItem('token');
	try {
		const response = await api.get('/users/me', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		const user = {
			id: response.data.id,
			mail: response.data.mail,
			name: response.data.name,
		};
		return user;
	} catch (err) {
		console.log(err);
	}
};

export const saveVote = async (data) => {
	const token = localStorageHelper.getItem('token');

	const response = await api.post(
		'/companies',
		{
			idCompany: data.idCompany,
			idUser: data.idUser,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	console.log(response);
	return response;
};

export const getCompanies = async () => {
	const token = localStorageHelper.getItem('token');

	const response = await api.get('/companies', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return response;
};
