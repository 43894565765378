import React from 'react';
import './BigButton.css';

function BigButton({ children, onClick, className }) {
  return (
    <button onClick={onClick} className={'Bbutton ${className}'}>
      {children}
    </button>
  );
}

export default BigButton; 
